import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  View,
  FlatList,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Dimensions,
  AppState,
} from "react-native";
import { BaseColor, Images, useTheme } from "../../config";
import { Header, Icon, OrderItem } from "../../components";
import { Flex, Image, Text, TextInput } from "../../ui";
import { useTranslation } from "react-i18next";
import { relativeToNow, uploadImage } from "../../utils";
import { useAuth } from "../../context/Auth";
import {
  getProfile,
  getProfilePictureUrl,
  readChat,
  subscribeMessages,
  TMessage,
  sendMessage,
  getCurrentUserId,
} from "../../models";
import { usePromise, useSubscription } from "../../hooks";
import { GiftedChat, Bubble, User } from "react-native-gifted-chat";
import { dateFromNow } from "../../utils/time";
import * as ImagePicker from "expo-image-picker";
import { minWidth } from "../../config/theme";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import Complain from "../../components/Complain";
import ImageModal from "../../components/ImageModal";

const screenWidth = Dimensions.get("window").width;

const getSysMessage = (senderId: string, type?: string, action?: string) => {
  if (type === "order") {
    if (action === "created") {
      return {
        sysTitle: "order_notification:new_order_title",
        sysDesc: "order_notification:new_order_desc",
      };
    }
    if (action === "accepted") {
      return {
        sysTitle: "order_notification:accepted_order_title",
        sysDesc: "order_notification:accepted_order_desc",
      };
    }
    if (action === "paid") {
      return {
        sysTitle: "order_notification:paid_order_title",
        sysDesc: "order_notification:paid_order_desc",
      };
    }
    if (action === "executed") {
      return {
        sysTitle: "order_notification:executed_order_title",
        sysDesc: "order_notification:executed_order_desc",
      };
    }
    if (action === "checked") {
      return {
        sysTitle: "order_notification:checked_order_title",
        sysDesc: "order_notification:checked_order_desc",
      };
    }
    if (action === "cancelled") {
      const sysTitle = "order_notification:cancelled_order_title";
      if (senderId) {
        return {
          sysTitle,
          sysDesc: "order_notification:cancelled_order_desc",
        };
      }
      return {
        sysTitle,
        sysDesc: "order_notification:cancelled_order_by_server_desc",
      };
    }
    if (action === 'updated amount') {
      return {
        sysTitle: 'order_notification:updated_amount_order_title',
        sysDesc: 'order_notification:updated_amount_order_desc',
      };
    }
  }
  return {};
};

const Messages = ({ route }: any) => {
  const { t, i18n } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { userId: otherUserId, name } = route.params;
  const displayName = decodeURI(name);
  let { userId: myId, realUserId } = useAuth();
  myId = getCurrentUserId();
  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });
  const messagesEndRef = useRef(null);
  const [input, setInput] = useState("");
  const [isScrollEnd, setIsScrollEnd] = useState(true);
  const [isFocusTextInput, setIsFocusTextInput] = useState(false);
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [uploadingImages, setUploadingImages] = useState<{
    [key: string]: { imageUrl?: string; progress: number };
  }>({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  // profile
  const myProfile = usePromise(() => getProfile(myId), [myId]);
  const otherProfile = usePromise(() => getProfile(otherUserId), [otherUserId]);
  const { displayName: otherUserDisplayName, avatarId: otherUserAvatarId } =
    otherProfile || {};
  useEffect(() => {
    {
      /*@ts-ignore*/
    }
    navigation.setParams({ name: otherProfile?.displayName });
  }, [otherProfile]);

  // messages
  const toGiftedChatMessage = useCallback(
    (message) => {
      const profile = message.senderId === myId ? myProfile : otherProfile;
      const otherUserAvatarUrl = getProfilePictureUrl(
        otherUserId,
        otherUserAvatarId
      );

      const { type: sysType, action: sysAction, order } = message.sys || {};
      const { sysTitle, sysDesc } = getSysMessage(
        message.senderId,
        sysType,
        sysAction
      );

      const isProvider = order?.providerId === myId;

      let statusColor = "";
      if (sysAction === "cancelled") {
        statusColor = BaseColor.dangerColor;
      } else if (sysAction === "checked") {
        statusColor = BaseColor.successColor;
      } else if (sysAction === "executed") {
        statusColor = isProvider
          ? BaseColor.successColor
          : BaseColor.warningColor;
      } else if (sysAction === "paid") {
        statusColor = isProvider
          ? BaseColor.warningColor
          : BaseColor.successColor;
      } else if (sysAction === "accepted") {
        statusColor = isProvider
          ? BaseColor.successColor
          : BaseColor.warningColor;
      } else {
        statusColor = BaseColor.warningColor;
      }

      return {
        _id: message.id,
        text:
          message.text ||
          (sysDesc &&
            `${t(sysDesc, { senderName: t("i_subject") })} ${t(
              "chat:system_sent"
            )}`),
        image: message.pictureUrl,
        pictureUrl: message.pictureUrl,
        pictureOriginUrl: message.pictureOriginUrl,
        createdAt: new Date(message.createdAt || Date.now()),
        statusString: sysTitle && t(sysTitle),
        statusColor,
        order,
        profile,
        user: {
          _id: message.senderId,
          ...(otherUserId === message.senderId
            ? {
                name: otherUserDisplayName,
                avatar: otherUserAvatarUrl || "",
              }
            : {}),
        },
        system: !!message.sys,
      };
    },
    [t, myId, otherUserId, myProfile, otherProfile]
  );

  const handleDataChange = useCallback((newData: TMessage[] | null) => {
    if (newData && newData.length > 0) {
      setMessages(newData);
    }
  }, []);

  useSubscription({
    subscription: subscribeMessages,
    variables: {
      userId: myId,
      otherUserId,
    },
    onChange: handleDataChange,
    limit: 200,
  });

  let giftedMessages = useMemo(
    () => messages.map(toGiftedChatMessage).reverse(),
    [toGiftedChatMessage, messages]
  );

  // appstate
  const handleAppStateChange = useCallback(
    (nextState) => {
      if (nextState === "active") {
        readChat(realUserId, myId, otherUserId);
      }
    },
    [otherUserId]
  );

  const handleOpenProfile = useCallback(() => {
    navigation.navigate("SitterDetailScreen", {
      sitterId: otherUserId,
      preRoute: "OrderDetail",
    });
  }, [otherUserId]);

  const handleClickImage = useCallback((url: string) => {
    setSelectedImageUrl(url);
    setShowImageModal(true);
  }, []);

  useEffect(() => {
    readChat(realUserId, myId, otherUserId);
    // const subscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      // subscription.remove();
    };
  }, [otherUserId]);

  const renderItem = (item: any) => {
    if (item.system) {
      const { order, profile, statusString, statusColor } = item;
      const { id, providerId, pictureId, type, dates, title, amount } =
        order || {};
      if (item.user._id === otherUserId) {
        return (
          <Flex paddingHorizontal={12}>
            <Flex>
              <OrderItem
                id={id}
                type={type}
                title={title}
                dates={dates}
                providerId={providerId}
                profile={profile}
                pictureId={pictureId}
                amount={amount}
                statusString={statusString || ""}
                statusColor={statusColor}
              />
            </Flex>
            <Flex paddingHorizontal={8}>
              <View style={{ paddingHorizontal: 8, flex: 7 }}>
                <View
                  style={[
                    styles.userContentMessage,
                    { backgroundColor: colors.primary },
                  ]}
                >
                  <Text body2 whiteColor>
                    {item.text}
                  </Text>
                </View>
              </View>
              <View style={styles.userContentDate}>
                <Text footnote numberOfLines={1}>
                  {dateFromNow(item.createdAt)}
                </Text>
              </View>
            </Flex>
          </Flex>
        );
      } else {
        return (
          <Flex paddingHorizontal={12}>
            <Flex>
              <OrderItem
                id={id}
                type={type}
                title={title}
                dates={dates}
                providerId={providerId}
                profile={profile}
                pictureId={pictureId}
                amount={amount}
                statusString={statusString || ""}
                statusColor={statusColor}
              />
            </Flex>
            <Flex paddingHorizontal={8}>
              <View style={{ paddingLeft: 8, flex: 7 }}>
                <View
                  style={[
                    styles.meContentMessage,
                    {
                      backgroundColor: BaseColor.whiteColor,
                      borderTopLeftRadius: 16,
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      borderTopRightRadius: 0,
                    },
                  ]}
                >
                  <Text body2>{item.text}</Text>
                </View>
              </View>
              <View style={styles.meContentDate}>
                <Text footnote numberOfLines={1}>
                  {dateFromNow(item.createdAt)}
                </Text>
              </View>
            </Flex>
          </Flex>
        );
      }
    } else {
      if (item.user._id === otherUserId) {
        return (
          <View
            style={[
              styles.userContent,
              item.image === "" ? {} : styles.userImageContent,
              { marginBottom: 10 },
            ]}
          >
            <TouchableOpacity onPress={handleOpenProfile}>
              <Image
                source={{ uri: item.user.avatar }}
                style={[styles.avatar, { borderColor: colors.accent }]}
              />
            </TouchableOpacity>
            <View style={{ paddingHorizontal: 8, flex: 7 }}>
              <Text caption1>{item.user.name}</Text>
              {item.pictureUrl === undefined ? (
                <View
                  style={[
                    styles.userContentMessage,
                    { backgroundColor: colors.primary },
                  ]}
                >
                  <Text body2 whiteColor>
                    {item.text}
                  </Text>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    handleClickImage(item.pictureUrl);
                  }}
                >
                  <Image
                    source={{ uri: item.pictureUrl }}
                    style={{
                      width:
                        screenWidth >= minWidth
                          ? minWidth * 0.7
                          : screenWidth * 0.7,
                      height:
                        screenWidth >= minWidth
                          ? (minWidth * 0.7 * 3) / 4
                          : (screenWidth * 0.7 * 3) / 4,
                      borderRadius: 20,
                      marginTop: 10,
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.userContentDate}>
              <Text footnote numberOfLines={1}>
                {dateFromNow(item.createdAt)}
              </Text>
            </View>
          </View>
        );
      } else {
        return (
          <View
            style={[
              styles.meContent,
              item.image === "" ? {} : styles.meImageContent,
            ]}
          >
            <View style={styles.meContentDate}>
              <Text footnote numberOfLines={1}>
                {dateFromNow(item.createdAt)}
              </Text>
            </View>
            <View style={{ paddingLeft: 8, flex: 7 }}>
              {item.pictureUrl === undefined ? (
                <View
                  style={[
                    styles.meContentMessage,
                    { backgroundColor: BaseColor.whiteColor },
                  ]}
                >
                  <Text body2>{item.text}</Text>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    handleClickImage(item.pictureUrl);
                  }}
                >
                  <Image
                    source={{ uri: item.pictureUrl }}
                    style={{
                      width:
                        screenWidth >= minWidth
                          ? minWidth * 0.7
                          : screenWidth * 0.7,
                      height:
                        screenWidth >= minWidth
                          ? (minWidth * 0.7 * 3) / 4
                          : (screenWidth * 0.7 * 3) / 4,
                      borderRadius: 20,
                      marginTop: 10,
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
        );
      }
    }
  };

  const scrollToEnd = () => {
    /*@ts-ignore*/
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (giftedMessages.length <= 0) return;
    scrollToEnd();
  }, [giftedMessages]);

  const handleSend = useCallback(
    (newMessage: { image?: string; imageUrl?: string; text?: string }) => {
      const imageUrl = newMessage.image;
      newMessage.imageUrl = imageUrl;
      let prevProgress = 0;
      sendMessage(otherUserId, newMessage, (progress, tempPictureId) => {
        if (prevProgress !== progress) {
          prevProgress = progress;

          // show image uploading progress
          setUploadingImages({
            ...uploadingImages,
            [tempPictureId]: {
              imageUrl,
              progress,
            },
          });
          if (progress === 1) {
            // hide image uploading progress
            setTimeout(() => {
              // TODO: fade out animation
              delete uploadingImages[tempPictureId];
              setUploadingImages(uploadingImages);
            }, 1000);
          }
        }
      });
    },
    [otherUserId, uploadingImages]
  );

  const handleSelectAvatar = useCallback(async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得權限");
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [1, 1],
      });
      if (!result.cancelled) {
        handleSend({ image: result.uri });
      }
    }
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={displayName}
        subTitle={
          otherProfile
            ? t("last seen {{time}}", {
                time: relativeToNow(
                  i18n.language,
                  otherProfile.lastCheckedInAt
                ),
              })
            : ""
        }
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.pop();
          // navigation.navigate("UserTab", {screen: "Chat"})
        }}
        /* @ts-ignore */
        style={{
          position: "fixed",
          top: 0,
          backgroundColor: "white",
          width: "100%",
          minWidth: minWidth,
          zIndex: 1,
        }}
        renderRight={() => {
          return (
            <Complain
              color={BaseColor.color1}
              context={{
                target: otherProfile,
                contextId: otherProfile?.id,
                contextType: "chats",
              }}
            />
          );
        }}
      />
      <ImageModal
        images={[selectedImageUrl]}
        visible={showImageModal}
        onDismiss={() => setShowImageModal(false)}
      />
      <KeyboardAvoidingView
        style={{
          flex: 1,
          justifyContent: "flex-end",
          paddingTop: 50,
          paddingBottom: 20,
          backgroundColor: colors.card,
        }}
        behavior={Platform.OS === "android" ? "height" : "padding"}
        keyboardVerticalOffset={offsetKeyboard}
        enabled
      >
        <View style={{ flex: 1 }}>
          <View style={{ paddingBottom: 76 }}>
            {giftedMessages.map((item) => (
              <View key={item._id}>{renderItem(item)}</View>
            ))}
          </View>
          <View ref={messagesEndRef} />
          <View style={styles.inputContent}>
            {isFocusTextInput ? (
              <>
                <TouchableOpacity
                  style={[styles.sendIcon]}
                  onPress={() => {
                    setIsFocusTextInput(false);
                  }}
                >
                  <Icon name="angle-right" size={24} color={colors.primary} />
                </TouchableOpacity>
              </>
            ) : (
              <>
                <TouchableOpacity
                  style={[styles.sendIcon]}
                  onPress={handleSelectAvatar}
                >
                  <Icon name="plus" size={20} color={colors.primary} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.sendIcon, { marginRight: 10 }]}
                  onPress={handleSelectAvatar}
                >
                  <Icon name="image" size={20} color={colors.primary} />
                </TouchableOpacity>
                {/* <TouchableOpacity
                    style={[styles.sendIcon, {marginRight: 8}]}
                    onPress={uploadImage}>
                    <Icon
                      name="camera"
                      size={20}
                      color={colors.primary}
                    />
                  </TouchableOpacity> */}
              </>
            )}

            <View style={{ flex: 1 }}>
              <TextInput
                style={{
                  borderRadius: 20,
                  padding: 10,
                  borderWidth: 0.5,
                  borderColor: BaseColor.placeholderTextColor,
                }}
                onChangeText={(text) => {
                  setInput(text);
                  setIsFocusTextInput(true);
                }}
                onFocus={() => {
                  setIsFocusTextInput(true);
                }}
                onSubmitEditing={() => {}}
                placeholder={t("messenger:type_message")}
                value={input}
              />
            </View>
            <TouchableOpacity
              style={[styles.sendIcon, { marginRight: "5.5rem" }]}
              onPress={() => {
                if (input !== "") {
                  handleSend({ text: input });
                  setInput("");
                  scrollToEnd();
                }
              }}
            >
              <Icon name="paper-plane" size={20} color={colors.primary} />
            </TouchableOpacity>
            {isScrollEnd ? (
              <></>
            ) : (
              <TouchableOpacity
                style={[
                  {
                    position: "absolute",
                    top: -30,
                    right: 15,
                    backgroundColor: colors.primary,
                    padding: 5,
                    borderRadius: 5,
                  },
                ]}
                onPress={scrollToEnd}
              >
                <Icon name="arrow-down" size={16} color={"white"} />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default Messages;

const styles = StyleSheet.create({
  uploadingBar: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  inputContent: {
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 20,
    alignItems: "center",
    flexDirection: "row",
    /* @ts-ignore */
    position: "fixed",
    bottom: 0,
    backgroundColor: "white",
    width: "100%",
    minWidth: minWidth,
  },
  sendIcon: {
    width: 30,
    height: 30,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
  },
  userContent: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
  },
  userImageContent: {},
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    marginTop: 24,
  },
  userContentMessage: {
    marginTop: 8,
    padding: 16,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    flex: 1,
  },
  userContentDate: {
    flex: 3,
    justifyContent: "flex-end",
  },
  meContent: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  meImageContent: {
    // maxHeight: 200
  },
  meContentDate: {
    flex: 3,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  meContentMessage: {
    marginTop: 8,
    padding: 16,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    flex: 1,
  },
});
